<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Gelir-Gider Listesi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Gelir-Gider Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Gelir-Gider Ekle",
          active: true,
        },
      ],
      values: {
        name: "",
        type: "",
      },
      TypeOptions:[
        {value: 1, text: "Gelir"},
        {value: 2, text: "Gider"}
      ],
    };
  },
  methods: {
    submit() {
      const api_url = process.env.VUE_APP_BASEURL + "/incomeexpensetype";
      const data = {
        name: this.values.name,
        type: this.values.type,
        isActive: 1
      } 
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/incomeexpensetype");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="name">Adı:</label>
                    <input
                      v-model="values.name"
                      type="text"
                      class="form-control"
                      id="name"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="type">Tipi:</label>
                    <b-form-select
                      v-model="values.type"
                      :options="TypeOptions"
                      class="form-control"
                      id="type"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Ekle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
